import { createContext, useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';
import mixpanel from 'mixpanel-browser';
import { authApi } from '../api/auth';
import { apiUrl } from '../config';
import { isDev } from '../utils/is-dev';
import gtm from '../lib/gtm';

const initialState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
  accessToken: null,
};

const handlers = {
  INITIALIZE: (state, action) => {
    const { isAuthenticated, user, accessToken } = action.payload;
    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user,
      accessToken
    };
  },
  LOGIN: (state, action) => {
    const { user, accessToken } = action.payload;
    return {
      ...state,
      isAuthenticated: true,
      user,
      accessToken,
    };
  },
  ME: (state, action) => {
    const { user } = action.payload;
    return {
      ...state,
      isAuthenticated: true,
      user,
    };
  },
  LOGOUT: (state) => ({
    ...state,
    isAuthenticated: false,
    user: null,
    accessToken: null
  }),
};

const reducer = (state, action) => (handlers[action.type]
  ? handlers[action.type](state, action)
  : state);

export const AuthContext = createContext({
  ...initialState,
  method: 'JWT',
  login: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  register: () => Promise.resolve(),
  me: () => Promise.resolve(),
  apiRequest: () => Promise.resolve(),
  getAccessToken: () => Promise.resolve(),
  recover: () => Promise.resolve(),
});

export const AuthProvider = (props) => {
  const { children } = props;
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    const initialize = async () => {
      try {
        const accessToken = localStorage.getItem('accessToken');
        const isExpired = await authApi.isExpired(accessToken);
        if (!isExpired) {
          const user = await authApi.me(accessToken);
          gtm.push({ event: 'initialized', email: user?.email, org: user?.org });
          mixpanel.track('initialized', { email: user?.email, org: user?.org });
          dispatch({
            type: 'INITIALIZE',
            payload: {
              isAuthenticated: true,
              accessToken,
              user,
            }
          });
        } else {
          dispatch({
            type: 'INITIALIZE',
            payload: {
              isAuthenticated: false,
              accessToken: null,
            }
          });
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: 'INITIALIZE',
          payload: {
            isAuthenticated: false,
            accessToken: null,
          }
        });
      }
    };

    initialize();
  }, []);

  const login = async (email, password) => {
    const { accessToken } = await authApi.login({ email, password });
    const user = await authApi.me(accessToken);
    localStorage.setItem('accessToken', accessToken);
    dispatch({
      type: 'LOGIN',
      payload: {
        user,
        accessToken,
      }
    });
  };

  const logout = async () => {
    gtm.push({ event: 'logout' });
    mixpanel.track('logout');
    localStorage.removeItem('accessToken');
    dispatch({ type: 'LOGOUT' });
  };

  const getAccessToken = async () => {
    const isExpired = await authApi.isExpired(state.accessToken);
    if (!isExpired) {
      return state.accessToken;
    }
    if (isDev) console.log('Token expired?', state.accessToken, state?.user);
    await logout();
    return null;
  };

  const me = async () => {
    const accessToken = await getAccessToken();
    if (accessToken) {
      const user = await authApi.me(accessToken);
      dispatch({
        type: 'ME',
        payload: {
          user
        }
      });
    } else {
      console.log('No accessToken?');
    }
  };

  const apiRequest = async (url = '', body = {}, method = 'POST') => {
    if (isDev) console.log('New API request:', url);
    try {
      const accessToken = await getAccessToken();
      if (accessToken) {
        return await fetch(`${apiUrl}${url}`, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json'
          },
          method,
          ...(method !== 'GET') && { body: JSON.stringify({
            ...body,
          }) },
        })
          .then(async (r) => {
            if (r.status === 403) { // If the response gave a 403 it means the accessToken is invalid
              if (isDev) console.log('403, invalid token', accessToken);
              return {}; // Run the api request again
            }
            if (r.ok) { // If the response was successful give back the data
              return r.json().catch((err) => {
                console.error('[Fetch Error]:', err);
                return {};
              });
            }
            return r.text().then((text) => { throw new Error(text); });
          });
      }
      console.log('No accessToken?');
      return null;
    } catch (err) {
      console.error('[Api Error]: ', err);
      throw new Error(err);
    }
  };

  const register = async (email, password = '', token = '') => {
    if (email && password && token) {
      await authApi.finishRegister({ email, password, token });
    } else {
      await authApi.startRegister({ email });
    }
  };

  const recover = async (email, password = '', token = '') => {
    if (email && password && token) {
      await authApi.finishRecover({ email, password, token });
    } else {
      await authApi.startRecover({ email });
    }
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'JWT',
        login,
        logout,
        register,
        apiRequest,
        me,
        getAccessToken,
        recover,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired
};
