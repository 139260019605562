import PropTypes from 'prop-types';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import toast from 'react-hot-toast';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormHelperText,
  Grid,
  MenuItem,
  IconButton,
  CircularProgress,
  Typography
} from '@mui/material';
import { useAuth } from '../hooks/use-auth';
import { Folders } from '../utils/folders';
import { Trash as TrashIcon } from '../icons/trash';
import { InputField } from './input-field';
import { ImageDropzone } from './image-dropzone';

export const ProductAssetDialog = (props) => {
  const { open, onClose, onExited, ...other } = props;
  const { apiRequest } = useAuth();

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      description: '',
      asset: null,
      name: '',
      category: '',
      folder: '',
      thumbnail: null,
      submit: null
    },
    validationSchema: Yup.object().shape({
      description: Yup.string().max(1024).required('Description name is required'),
      asset: Yup.mixed().required('Asset file is required'),
      name: Yup.string().max(255).required('Asset name is required'),
      category: Yup.string().max(255).required('Asset category is required'),
      folder: Yup.string().max(255),
      thumbnail: Yup.mixed().required('Thumbnail is required'),
    }),
    onSubmit: async (values, helpers) => {
      try {
        const { asset, thumbnail } = await apiRequest('/assetPresign', {
          asset_key: values.asset.name,
          asset_contentType: values.asset.type,
          thumbnail_key: values.thumbnail.name,
          thumbnail_contentType: values.thumbnail.type,
        });

        if (asset && thumbnail) {
          await fetch(asset.uploadURL, {
            method: 'PUT',
            body: values.asset
          });

          await fetch(thumbnail.uploadURL, {
            method: 'PUT',
            body: values.thumbnail
          });

          await apiRequest('/assetSave', {
            key: values.asset.name,
            thumbnail: values.thumbnail.name,
            name: values.name,
            category: values.category,
            ...values.folder && { folder: values.folder },
            description: values.description
          });

          toast.success('Asset Saved');
          helpers.setStatus({ success: true });
          helpers.setSubmitting(false);
        } else {
          helpers.setStatus({ success: false });
          helpers.setErrors({ submit: 'Saving asset failed' });
          helpers.setSubmitting(false);
        }

        onClose?.();
      } catch (err) {
        console.error(err);
        helpers.setStatus({ success: false });
        helpers.setErrors({ submit: err.message });
        helpers.setSubmitting(false);
      }
    }
  });

  return (
    <Dialog
      onClose={onClose}
      open={open}
      PaperProps={{
        sx: {
          width: '100%'
        }
      }}
      TransitionProps={{
        onExited: () => {
          onExited?.();
          formik.resetForm();
        }
      }}
      {...other}
    >
      <DialogTitle>
        Add Asset
      </DialogTitle>
      <DialogContent>
        <Grid
          container
          spacing={2}
        >
          <Grid
            item
            xs={12}
          >
            <InputField
              error={Boolean(formik.touched.name && formik.errors.name)}
              fullWidth
              helperText={formik.touched.name && formik.errors.name}
              label="Name"
              name="name"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.name}
            />
          </Grid>
          <Grid
            item
            xs={12}
          >
            <InputField
              error={Boolean(formik.touched.description && formik.errors.description)}
              fullWidth
              helperText={formik.touched.description && formik.errors.description}
              label="Description"
              multiline
              name="description"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              rows={4}
              value={formik.values.description}
            />
          </Grid>

          <Grid
            item
            xs={12}
          >
            <InputField
              error={Boolean(formik.touched.folder && formik.errors.folder)}
              fullWidth
              helperText={formik.touched.folder && formik.errors.folder}
              label="Folder"
              name="folder"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              select
              value={formik.values.folder}
            >
              <MenuItem
                value=""
              >
                No folder
              </MenuItem>
              {[...Folders].map((op) => (
                <MenuItem
                  key={op}
                  value={op}
                >
                  { op }
                </MenuItem>
              ))}
            </InputField>
          </Grid>

          <Grid
            item
            xs={12}
          >
            <InputField
              error={Boolean(formik.touched.category && formik.errors.category)}
              fullWidth
              helperText={formik.touched.category && formik.errors.category}
              label="Category"
              name="category"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.category}
            />
          </Grid>

          <Grid
            item
            xs={12}
          >
            <Typography
              color="textPrimary"
              sx={{ mb: 1.25 }}
              variant="subtitle2"
            >
              Thumbnail
            </Typography>
            {formik.values.thumbnail
              ? (
                <Box
                  sx={{
                    borderRadius: 1,
                    boxShadow: '0 0 0 1px rgba(24, 33, 77, 0.23)',
                    display: 'flex',
                    position: 'relative',
                    width: 'fit-content',
                    '& img': {
                      borderRadius: 1,
                      display: 'block',
                      maxWidth: 126
                    },
                    '&:hover': {
                      boxShadow: (theme) => `0 0 0 1px ${theme.palette.primary.main}`,
                      '& > button': {
                        display: 'block'
                      },
                      '& img': {
                        opacity: 0.3
                      }
                    }
                  }}
                >
                  <img
                    alt={formik.values.name}
                    src={URL.createObjectURL(formik.values.thumbnail)}
                  />
                  <IconButton
                    color="error"
                    onClick={() => formik.setFieldValue('thumbnail', null)}
                    sx={{
                      display: 'none',
                      left: 0,
                      position: 'absolute',
                      top: 0
                    }}
                  >
                    <TrashIcon fontSize="small" />
                  </IconButton>
                </Box>
              ) : (
                <ImageDropzone
                  onDrop={(files) => formik.setFieldValue('thumbnail', files[0])}
                  sx={{
                    minHeight: 126,
                    maxWidth: 126
                  }}
                />
              )}
          </Grid>

          <Grid
            item
            xs={12}
          >
            <Typography
              color="textPrimary"
              sx={{ mb: 1.25 }}
              variant="subtitle2"
            >
              Asset File
            </Typography>
            {
              formik.values.asset && (
              <Typography
                sx={{ borderRadius: 1, mb: 1, padding: '10px 20px', backgroundColor: 'success.main', color: '#FFF' }}
                variant="subtitle2"
              >
                { formik.values.asset.name }
              </Typography>
              )
            }
            <ImageDropzone
              onDrop={(files) => {
                console.log(files[0]);
                formik.setFieldValue('asset', files[0]);
              }}
              sx={{
                minHeight: 126,
                maxWidth: 126
              }}
            />
          </Grid>

          {formik.errors.submit && (
            <Grid
              item
              xs={12}
            >
              <FormHelperText error>
                {formik.errors.submit}
              </FormHelperText>
            </Grid>
          )}
        </Grid>
      </DialogContent>
      {(formik?.isSubmitting && !formik?.isValidating) && (
        <CircularProgress style={{ margin: '20px auto', display: 'block' }} />
      )}
      <DialogActions>
        <Button
          color="primary"
          onClick={onClose}
          variant="text"
        >
          Cancel
        </Button>
        <Button
          color="primary"
          disabled={(formik?.isSubmitting && !formik?.isValidating)}
          onClick={() => { formik.handleSubmit(); }}
          variant="contained"
        >
          Add Asset
        </Button>
      </DialogActions>
    </Dialog>
  );
};

ProductAssetDialog.defaultProps = {
  open: false
};

ProductAssetDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onExited: PropTypes.func
};
