import PropTypes from 'prop-types';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormHelperText,
  Grid
} from '@mui/material';
import toast from 'react-hot-toast';
import mixpanel from 'mixpanel-browser';
import { InputField } from './input-field';
import { useAuth } from '../hooks/use-auth';
import gtm from '../lib/gtm';

export const OrganizationInviteDialog = (props) => {
  const { open, onClose, ...other } = props;
  const { apiRequest, user } = useAuth();

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      email: '',
      org: user?.admin ? '' : user?.org,
      submit: null
    },
    validationSchema: Yup.object().shape({
      email: Yup.string().email('Must be a valid email').max(255).required('Email is required'),
    }),
    onSubmit: async (values, helpers) => {
      try {
        gtm.push({ event: 'invited', email: values.email, org: values.org, });
        mixpanel.track('invited', {
          email: values.email,
          org: values.org,
        });

        await apiRequest('/invite', {
          email: values.email,
          org: values.org,
        });
        toast.success('User added to organization');

        helpers.setStatus({ success: true });
        helpers.setSubmitting(false);
        onClose?.();
      } catch (err) {
        console.error(err);
        helpers.setStatus({ success: false });
        helpers.setErrors({ submit: err.message });
        helpers.setSubmitting(false);
      }
    }
  });

  return (
    <Dialog
      onClose={onClose}
      open={open}
      TransitionProps={{
        onExited: () => formik.resetForm()
      }}
      {...other}
    >
      <form onSubmit={formik.handleSubmit}>
        <DialogTitle>
          { formik.values.id ? 'Update a team member' : 'Add a team member'}
        </DialogTitle>
        <DialogContent>
          <Grid
            container
            spacing={2}
          >
            <Grid
              item
              xs={12}
            >
              <InputField
                error={Boolean(formik.touched.email && formik.errors.email)}
                fullWidth
                helperText={formik.touched.email && formik.errors.email}
                label="Email address"
                name="email"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                type="email"
                value={formik.values.email}
              />
            </Grid>
            <Grid
              item
              xs={12}
            >
              <InputField
                error={Boolean(formik.touched.org && formik.errors.org)}
                fullWidth
                helperText={formik.touched.org && formik.errors.org}
                label="Organisation"
                name="org"
                disabled={Boolean(!user.admin)}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                type="org"
                value={formik.values.org}
              />
            </Grid>
            {formik.errors.submit && (
              <Grid
                item
                xs={12}
              >
                <FormHelperText error>
                  {formik.errors.submit}
                </FormHelperText>
              </Grid>
            )}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            onClick={onClose}
            type="button"
            variant="text"
          >
            Cancel
          </Button>
          <Button
            color="primary"
            type="submit"
            variant="contained"
            disabled={formik.isSubmitting}
          >
            Add User
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

OrganizationInviteDialog.defaultProps = {
  open: false,
};

OrganizationInviteDialog.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool
};
