import { Outlet } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { Footer } from '../components/footer';

const DashboardLayoutRoot = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  height: '100%',
}));

const DashboardLayoutContent = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  height: '100%'
}));

export const DashboardLayout = () => (
  <DashboardLayoutRoot>
    <DashboardLayoutContent>
      <Outlet />
      <Footer />
    </DashboardLayoutContent>
  </DashboardLayoutRoot>
);
