import { Box, Container, Link, Typography } from '@mui/material';
import { useAuth } from '../hooks/use-auth';

const links = [
  {
    label: 'Main Website',
    href: 'https://inception-labs.com/'
  },
  {
    label: 'Privacy Policy',
    href: 'https://inception-labs.com/privacy-policy'
  }
];

export const Footer = () => {
  const { logout, isAuthenticated } = useAuth();
  return (
    <Box sx={{ backgroundColor: 'background.default' }}>
      <Container
        maxWidth="lg"
        sx={{
          display: 'flex',
          flexDirection: {
            sm: 'row',
            xs: 'column'
          },
          py: 3,
          '& a': {
            mt: {
              sm: 0,
              xs: 1
            },
            '&:not(:last-child)': {
              mr: {
                sm: 5,
                xs: 0
              }
            }
          }
        }}
      >
        <Typography
          color="textSecondary"
          variant="body2"
        >
          ©
          {` ${(new Date().getFullYear())} `}
          Inception Labs
        </Typography>
        <Box sx={{ flexGrow: 1 }} />
        {links.map((link) => (
          <Link
            color="textSecondary"
            href={link.href}
            key={link.label}
            target="_blank"
            underline="none"
            variant="body2"
          >
            {link.label}
          </Link>
        ))}
        { isAuthenticated && (
          <Typography
            color="textSecondary"
            variant="body2"
            sx={{ cursor: 'pointer' }}
            onClick={() => { logout(); }}
          >
            Log out
          </Typography>
        )}
      </Container>
    </Box>
  );
};
