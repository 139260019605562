import { useState, useEffect } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { Box, CircularProgress } from '@mui/material';
import PropTypes from 'prop-types';
import { isDev } from '../utils/is-dev';
import { useAuth } from '../hooks/use-auth';
import { Login } from '../containers/login';

export const AuthGuard = (props) => {
  const { children } = props;
  const { isAuthenticated, getAccessToken } = useAuth();
  const location = useLocation();
  const [requestedLocation, setRequestedLocation] = useState(null);
  const [loadingLocation, setLoadingLocation] = useState(null);

  useEffect(() => {
    async function checkToken() {
      if (isDev) console.log('Checking token', location.pathname);
      await getAccessToken();
      if (isDev) console.log('Finished checking token', location.pathname);
      setLoadingLocation(location.pathname);
    }
    if (isAuthenticated) {
      checkToken();
    }
  }, [isAuthenticated, location.pathname]);

  if (!isAuthenticated) {
    if (location.pathname !== requestedLocation) {
      setRequestedLocation(location.pathname);
    }

    return (
      <Login />
    );
  }

  // This is done so that in case the route changes by any chance through other
  // means between the moment of request and the render we navigate to the initially
  // requested route.
  if (requestedLocation && location.pathname !== requestedLocation) {
    setRequestedLocation(null);
    return <Navigate to={requestedLocation} />;
  }

  if (location.pathname !== loadingLocation) {
    return (
      <Box sx={{ flex: 1, flexGrow: 1, height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <CircularProgress />
      </Box>
    );
  }

  return <>{children}</>;
};

AuthGuard.propTypes = {
  children: PropTypes.node
};
