import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Link as RouterLink } from 'react-router-dom';
import {
  Box,
  Button,
  Card,
  CardHeader,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';
import toast from 'react-hot-toast';
import mixpanel from 'mixpanel-browser';
import { ArrowLeft as ArrowLeftIcon } from '../icons/arrow-left';
import { OrganizationInviteDialog } from '../components/organization-invite-dialog';
import { Scrollbar } from '../components/scrollbar';
import { Plus as PlusIcon } from '../icons/plus';
import gtm from '../lib/gtm';
import { useAuth } from '../hooks/use-auth';
import { useDialog } from '../hooks/use-dialog';
import { ConfirmationDialog } from '../components/confirmation-dialog';

export const OrganizationTeam = () => {
  const [openInvite, handleOpenInvite, handleCloseInvite] = useDialog();
  const [deleteDialogOpen, handleOpenDeleteDialog, handleCloseDeleteDialog] = useDialog();
  const [selectedUser, setUser] = useState(null);
  const { apiRequest, user, me } = useAuth();
  console.log(user);
  useEffect(() => {
    gtm.push({ event: 'page_view' });
    mixpanel.track('page_view');
  });

  const handleDeleteUser = () => {
    gtm.push({ event: 'user_removed', email: selectedUser.email });
    mixpanel.track('user_removed', { email: selectedUser.email });
    apiRequest('/remove', {
      email: selectedUser.email
    }).then(() => {
      toast.success('User removed');
      setUser(null);
      me();
    }).catch((err) => console.log(err));
    handleCloseDeleteDialog();
  };

  return (
    <>
      <Helmet>
        <title>Team | Inception Labs Media</title>
      </Helmet>
      <Box sx={{ mb: 2 }}>
        <Button
          color="primary"
          component={RouterLink}
          startIcon={<ArrowLeftIcon />}
          to="/dashboard"
          variant="text"
        >
          Return
        </Button>
      </Box>
      <Card variant="outlined">
        <CardHeader
          action={(
            <Button
              color="primary"
              onClick={handleOpenInvite}
              size="small"
              startIcon={(
                <PlusIcon />
              )}
              variant="contained"
            >
              Add
            </Button>
          )}
          title="Members"
        />
        <Divider />
        <Scrollbar>
          <Table sx={{ minWidth: 700 }}>
            <TableHead>
              <TableRow>
                <TableCell>
                  Email
                </TableCell>
                <TableCell>
                  Organisation
                </TableCell>
                <TableCell>
                  Actions
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {user?.users?.length ? user.users.map((member) => (
                <TableRow key={member.email}>
                  <TableCell>
                    {member.email}
                  </TableCell>
                  <TableCell
                    sx={{
                      color: 'text.secondary'
                    }}
                  >
                    {member.org}
                  </TableCell>
                  <TableCell sx={{ width: 145 }}>
                    <Box sx={{ display: (member.admin || member.owner) ? 'none' : 'flex' }}>
                      <Button
                        color="primary"
                        sx={{ cursor: 'pointer' }}
                        variant="subtitle2"
                        onClick={() => {
                          setUser(member);
                          handleOpenDeleteDialog();
                        }}
                      >
                        Remove
                      </Button>
                    </Box>
                  </TableCell>
                </TableRow>
              )) : <></>}
            </TableBody>
          </Table>
        </Scrollbar>
      </Card>
      <OrganizationInviteDialog
        user={selectedUser}
        onClose={() => {
          handleCloseInvite();
          setUser(null);
          me();
        }}
        open={openInvite}
      />
      <ConfirmationDialog
        message="Are you sure you want to remove this user?"
        onCancel={handleCloseDeleteDialog}
        onConfirm={handleDeleteUser}
        open={deleteDialogOpen}
        title="Remove user"
        variant="error"
      />
    </>
  );
};
