/* eslint-disable */
import { useEffect, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import {
  Box,
  Button,
  Container,
  Card,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Tab,
  Tabs
} from '@mui/material';
import { Scrollbar } from '../components/scrollbar';
import gtm from '../lib/gtm';
import mixpanel from 'mixpanel-browser';
import { useAuth } from '../hooks/use-auth';
import { useDialog } from '../hooks/use-dialog';
import { ProductAssetDialog } from '../components/product-asset-dialog';
import { ConfirmationDialog } from '../components/confirmation-dialog';
import { AutocompleteField } from '../components/autocomplete-field';
import { InputField } from '../components/input-field';
import { Pagination } from '../components/pagination';
import { applyPagination } from '../utils/apply-pagination';
import { isDev } from '../utils/is-dev';
import { Folders } from '../utils/folders';

export const Assets = () => {
  const { user, me, apiRequest } = useAuth();
  if (isDev) console.log(user);
  const [assetDialogOpen, handleOpenAssetDialog, handleCloseAssetDialog] = useDialog();
  const [deleteDialogOpen, handleOpenDeleteDialog, handleCloseDeleteDialog] = useDialog();
  const [selectedAsset, setSelectedAsset] = useState(null);
  const [categories, setCategories] = useState([]);
  const [search, setSearch] = useState('');
  const [view, setView] = useState('');

  const filteredAssets = user?.assets?.length ? user.assets.filter((asset) => {
    if(view?.length && asset?.folder?.toLowerCase() !== view.toLowerCase()) {
      return false;
    }
    if (categories.length && !categories.includes(asset.category)) {
      return false;
    }
    if (search?.length && !JSON.stringify(asset).toLowerCase().includes(search.toLowerCase())) {
      return false;
    }
    return true;
  }) : [];

  const [page, setPage] = useState(1);
  const paginatedAutomations = applyPagination(filteredAssets, page - 1, 25);

  const handleExitedDialog = () => {
    me();
  };

  const handleDeleteAsset = async () => {
    gtm.push({ event: 'deleted', email: user?.email, key: selectedAsset.key, category: selectedAsset.category });
    mixpanel.track('deleted', { email: user?.email, key: selectedAsset.key, category: selectedAsset.category });

    await apiRequest('/assetDelete', {
      key: selectedAsset.key,
      category: selectedAsset.category,
    });
    me();
    handleCloseDeleteDialog();
  };

  const downloadAsset = async (asset) => {
    gtm.push({ event: 'downloaded', email: user?.email, key: asset.key, category: asset.category });
    mixpanel.track('downloaded', { email: user?.email, key: asset.key, category: asset.category });
    const downloadLink = await apiRequest(`/assetDownload/${asset.key}`, undefined, 'GET');
    const link = document.createElement('a');
    link.href = downloadLink;
    link.target = '_blank';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  useEffect(() => {
    gtm.push({ event: 'page_view' });
    mixpanel.track('page_view');
  }, []);

  return (
    <>
      <Helmet>
        <title>Media | Inception Labs</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          flexGrow: 1,
          py: 2
        }}
      >
        <Container maxWidth="lg">
          <Box
            sx={{
              alignItems: 'center',
              display: 'flex',
              flexWrap: 'wrap',
              gap: '20px',
              mb: 4,
            }}
          >
            <img
              alt="logo"
              src="/static/logo.png"
              loading="lazy"
              style={{
                display: 'block',
                width: '288px',
              }}
            />
            <Box sx={{ flexGrow: 1 }} />
            { user.admin && (
              <Button
                size="large"
                startIcon={<AddPhotoAlternateIcon fontSize="small" />}
                variant="contained"
                onClick={() => {
                  handleOpenAssetDialog();
                }}
              >
                <Box>Add Asset</Box>
              </Button>
            )}

            <Button
              size="large"
              startIcon={<ManageAccountsIcon fontSize="small" />}
              variant="contained"
              component={RouterLink}
              to="/dashboard/team"
            >
              <Box>Manage Users</Box>
            </Button>
          </Box>

          <Card variant="outlined">
            <Box
            sx={{ display: 'flex', justifyContent: 'flex-start', ml: 2 }}
            >
              <Tabs
                onChange={(event, value) => setView(value)}
                allowScrollButtonsMobile
                value={view}
                variant="scrollable"
              >
                <Tab
                  label="All Files"
                  value=""
                />
                {[...Folders].map((option) => (
                  <Tab
                    key={option}
                    label={option}
                    value={option}
                  />
                ))}
              </Tabs>
            </Box>
            <Divider />
            <Box sx={{ display: 'flex', padding: 1, gap: 1 }}>
              <AutocompleteField
                label=""
                placeholder="Category"
                name="category"
                fullWidth
                multiple
                filterSelectedOptions
                onChange={(event, value) => {
                  gtm.push({ event: 'categories_updated', categories: value });
                  mixpanel.track('categories_updated', { categories: value });
                  setPage(1);
                  setCategories(value);
                }}
                value={categories}
                options={[...new Set(user.assets.map((x) => x.category))]}
              />
              <InputField
                label=""
                placeholder="Search"
                name="search"
                fullWidth
                onBlur={(event) => {
                  gtm.push({ event: 'searched', query: event.target.value });
                  mixpanel.track('searched', { query: event.target.value });
                }}
                onChange={(event) => {
                  setPage(1);
                  setSearch(event.target.value);
                }}
                value={search}
              />
            </Box>
            <Divider />
            <Scrollbar>
              <Table sx={{ minWidth: 700 }}>
                <TableHead>
                  <TableRow>
                    <TableCell />
                    <TableCell>
                      Name
                    </TableCell>
                    <TableCell>
                      Category
                    </TableCell>
                    <TableCell />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {paginatedAutomations?.length ? paginatedAutomations.map((asset) => (
                    <TableRow key={asset.key}>
                      <TableCell sx={{ width: 100 }}>
                        <img
                          src={`https://il-media-thumbnails.s3.ap-southeast-2.amazonaws.com/${asset.thumbnail}`}
                          alt={asset.name}
                          onClick={() => {
                            downloadAsset(asset);
                          }}
                          style={{
                            cursor: 'pointer',
                            width: '80px',
                            height: '80px',
                            borderRadius: '12px',
                            objectFit: 'cover',
                            boxShadow: '0px 1px 3px rgb(9 30 66 / 12%)'
                          }}
                        />
                      </TableCell>
                      <TableCell sx={{ width: 500 }}>
                        <Typography
                          color="text.primary"
                          variant="body2"
                        >
                          {asset.name}
                        </Typography>
                        <Typography
                          color="text.primary"
                          variant="caption"
                        >
                          {asset.description}
                        </Typography>
                      </TableCell>
                      <TableCell
                        sx={{
                          color: 'text.secondary'
                        }}
                      >
                        {asset.category}
                      </TableCell>
                      <TableCell sx={{ width: 240 }}>
                        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                          <Button
                            color="primary"
                            sx={{ cursor: 'pointer' }}
                            variant="contained"
                            onClick={() => {
                              downloadAsset(asset);
                            }}
                          >
                            Download
                          </Button>
                          {user.admin && (
                          <Button
                            color="error"
                            sx={{ cursor: 'pointer', ml: 2 }}
                            variant="contained"
                            onClick={() => {
                              setSelectedAsset(asset);
                              handleOpenDeleteDialog();
                            }}
                          >
                            Remove
                          </Button>
                          )}
                        </Box>
                      </TableCell>
                    </TableRow>
                  )) : <></>}
                </TableBody>
              </Table>
            </Scrollbar>
            <Divider sx={{ mt: 'auto' }} />
            <Pagination
              onPageChange={(newPage) => {
                gtm.push({ event: 'page_change', page: newPage });
                mixpanel.track('page_change', { page: newPage });
                setPage(newPage);
              }}
              page={page}
              pageSize={25}
              rowsCount={filteredAssets.length}
            />
          </Card>

        </Container>
        <ProductAssetDialog
          onClose={handleCloseAssetDialog}
          onExited={handleExitedDialog}
          open={assetDialogOpen}
        />
        <ConfirmationDialog
          message="Are you sure you want to delete this asset? This can't be undone."
          onCancel={handleCloseDeleteDialog}
          onConfirm={handleDeleteAsset}
          open={deleteDialogOpen}
          title="Delete asset"
          variant="error"
        />
      </Box>
    </>
  );
};
