import { apiUrl } from '../config';

class AuthApi {
  async login({ email, password }) {
    try {
      return await fetch(`${apiUrl}/login`, {
        method: 'POST',
        body: JSON.stringify({ email, password })
      })
        .then((r) => {
          if (r.ok) {
            return r.json();
          }

          throw new Error('Something went wrong.');
        });
    } catch (err) {
      console.error('[Auth Api]: ', err);
      throw new Error('You have entered an invalid email or password.');
    }
  }

  async isExpired(accessToken) {
    if (!accessToken) {
      console.log('No accessToken');
      return true;
    }
    const { exp } = JSON.parse(atob(accessToken.split('.')[1]));
    if (!exp) {
      console.log('No exp');
      return true;
    }
    const d = new Date(0);
    d.setUTCSeconds(exp);
    return d <= new Date();
  }

  async finishRegister({ email, password, token }) {
    try {
      return await fetch(`${apiUrl}/register`, {
        method: 'POST',
        body: JSON.stringify({ email, password, reset_code: token })
      })
        .then((r) => {
          if (r.ok) {
            return r.json();
          }
          throw new Error('Something went wrong.');
        });
    } catch (err) {
      console.error('[Auth Api]: ', err);
      throw new Error('You have entered an invalid verification code or email.');
    }
  }

  async startRegister({ email }) {
    try {
      return await fetch(`${apiUrl}/register`, {
        method: 'POST',
        body: JSON.stringify({ email })
      })
        .then((r) => {
          if (r.ok) {
            return r.json();
          }
          throw new Error('Something went wrong.');
        });
    } catch (err) {
      console.error('[Auth Api]: ', err);
      throw new Error('You have entered an invalid email.');
    }
  }

  async finishRecover({ email, password, token }) {
    try {
      return await fetch(`${apiUrl}/register`, {
        method: 'POST',
        body: JSON.stringify({ email, password, reset_code: token })
      })
        .then((r) => {
          if (r.ok) {
            return r.json();
          }
          throw new Error('Something went wrong.');
        });
    } catch (err) {
      console.error('[Auth Api]: ', err);
      throw new Error('You have entered an invalid verification code or email.');
    }
  }

  async startRecover({ email }) {
    console.log(email);
    try {
      return await fetch(`${apiUrl}/register`, {
        method: 'POST',
        body: JSON.stringify({ email })
      })
        .then((r) => {
          if (r.ok) {
            return r.json();
          }
          throw new Error('Something went wrong.');
        });
    } catch (err) {
      console.error('[Auth Api]: ', err);
      throw new Error('You have entered an invalid email.');
    }
  }

  async me(accessToken) {
    if (!accessToken) {
      return null;
    }
    try {
      return await fetch(`${apiUrl}/me`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-type': 'application/json',
        },
        method: 'GET'
      })
        .then((r) => {
          if (r.ok) {
            return r.json();
          }
          throw new Error('Something went wrong.');
        });
    } catch (err) {
      console.error('[me Api]: ', err);
      throw new Error('Getting me failed.');
    }
  }
}

export const authApi = new AuthApi();
