export const lightPrimary = {
  contrast: '#ffffff',
  dark: '#cf8030',
  light: '#cf8030',
  main: '#cf8030'
};

export const lightSecondary = {
  contrast: '#ffffff',
  dark: '#38319f',
  light: '#736Be9',
  main: '#5046e4'
};

export const lightError = {
  contrast: '#ffffff',
  dark: '#a53531',
  light: '#ef6f6b',
  main: '#ec4c47'
};

export const lightWarning = {
  contrast: '#ffffff',
  dark: '#975b1e',
  light: '#e09b55',
  main: '#d9822b'
};

export const lightInfo = {
  contrast: '#ffffff',
  dark: '#0b4e8d',
  light: '#3f8cd4',
  main: '#1070CA'
};

export const lightSuccess = {
  contrast: '#ffffff',
  dark: '#47B881',
  light: '#47B881',
  main: '#47B881'
};

export const lightBackground = {
  default: '#F9FAFB',
  paper: '#ffffff'
};

export const lightText = {
  primary: '#212B36',
  secondary: '#66788A'
};

export const lightNeutral = {
  100: '#f3f4f7',
  200: '#e1e3ea',
  300: '#d5d8e1',
  400: '#b7bdcc',
  500: '#9aa1b7',
  600: '#7c85a2',
  700: '#3f455a',
  800: '#2a2f3c',
  900: '#354147'
};

export const darkPrimary = {
  contrast: '#ffffff',
  dark: '#cf8030',
  light: '#cf8030',
  main: '#cf8030'
};

export const darkSecondary = {
  contrast: '#ffffff',
  dark: '#5659b2',
  light: '#9599ff',
  main: '#7b80ff'
};

export const darkError = {
  contrast: '#ffffff',
  dark: '#85372b',
  light: '#eb6d57',
  main: '#e6492d'
};

export const darkWarning = {
  contrast: '#ffffff',
  dark: '#8D682d',
  light: '#f7bb57',
  main: '#f6ab2e'
};

export const darkInfo = {
  contrast: '#ffffff',
  dark: '#1D4580',
  light: '#4483df',
  main: '#1664d8'
};

export const darkSuccess = {
  contrast: '#ffffff',
  dark: '#47B881',
  light: '#47B881',
  main: '#47B881'
};

export const darkBackground = {
  default: '#111318',
  paper: '#111318'
};

export const darkText = {
  primary: '#d8dade',
  secondary: '#868998'
};

export const darkNeutral = {
  100: '#354147',
  200: '#2b2f3c',
  300: '#40444f',
  400: '#545863',
  500: '#6c6f7f',
  600: '#868998',
  700: '#a1a4af',
  800: '#bdbfc7',
  900: '#d8dade'
};
