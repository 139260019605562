import { Suspense, lazy } from 'react';
import { Navigate } from 'react-router-dom';
import { AuthGuard } from './components/auth-guard';
import { GuestGuard } from './components/guest-guard';
import { LoadingScreen } from './components/loading-screen';
import { DashboardLayout } from './containers/dashboard-layout';

/* Our Parent Frames */
import { Simple } from './containers/simple';

/* Our Page Frames */
import { Assets } from './containers/assets';
import { OrganizationTeam } from './containers/organization-team';

const Loadable = (Component) => (props) => (
  <Suspense fallback={<LoadingScreen />}>
    <Component {...props} />
  </Suspense>
);

// Not found pages
const NotFound = Loadable(lazy(() => import('./containers/not-found').then((module) => ({ default: module.NotFound }))));

// Auth pages
const Login = Loadable(lazy(() => import('./containers/login').then((module) => ({ default: module.Login }))));
const PasswordRecovery = Loadable(lazy(() => import('./containers/password-recovery').then((module) => ({ default: module.PasswordRecovery }))));
const PasswordReset = Loadable(lazy(() => import('./containers/password-reset').then((module) => ({ default: module.PasswordReset }))));
const Register = Loadable(lazy(() => import('./containers/register').then((module) => ({ default: module.Register }))));

const routes = [
  {
    path: '/',
    element: (
      <Navigate
        to="/dashboard"
        replace
      />
    )
  },
  {
    path: 'login',
    element: (
      <GuestGuard>
        <Login />
      </GuestGuard>
    )
  },
  {
    path: 'register',
    element: (
      <GuestGuard>
        <Register />
      </GuestGuard>
    )
  },
  {
    path: 'password-recovery',
    element: (
      <GuestGuard>
        <PasswordRecovery />
      </GuestGuard>
    )
  },
  {
    path: 'password-reset',
    element: (
      <PasswordReset />
    )
  },
  {
    path: 'dashboard',
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '/',
        element: <Simple />,
        children: [
          {
            path: '/',
            element: <Assets />
          }
        ]
      },
      {
        path: 'team',
        element: <Simple />,
        children: [
          {
            path: '/',
            element: <OrganizationTeam />
          }
        ]
      },
    ]
  },
  {
    path: '*',
    element: <NotFound />
  }
];

export default routes;
