import { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { Box, Card, CardContent, Container, Grid } from '@mui/material';
import mixpanel from 'mixpanel-browser';
import { LoginJwt } from '../components/auth/login-jwt';
import { useAuth } from '../hooks/use-auth';
import gtm from '../lib/gtm';

export const Login = () => {
  useAuth();

  useEffect(() => {
    gtm.push({ event: 'page_view' });
    mixpanel.track('page_view');
  }, []);

  return (
    <>
      <Helmet>
        <title>Login | Inception Labs</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          pt: '64px'
        }}
      >
        <Box sx={{ py: 9 }}>
          <Container maxWidth="md">
            <Grid
              container
              spacing={6}
              justifyContent="center"
            >
              <Grid
                item
                md={6}
                xs={12}
              >
                <Card
                  sx={{ backgroundColor: 'background.default' }}
                  elevation={0}
                >
                  <CardContent>
                    <LoginJwt />
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Container>
        </Box>
      </Box>
    </>
  );
};
